import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/home.vue'
import project from '../views/project.vue'
import about from '../views/about.vue'
import institution from '../views/institution.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/project',
    name: 'project',
    component: project
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/institution',
    name: 'institution',
    component: institution
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

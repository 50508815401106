import swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
  name: 'home',
  data: function data() {
    return {
      bannerlist: [// {src: require('../assets/banner1.jpg'),text:require('../assets/banner_text1.png')},
      // {src: require('../assets/banner2.jpg'),text:require('../assets/banner_text2.png')},
      {
        src: require('../assets/banner1.jpg'),
        text: '芝士未来 — 方案灵活，一站式服务'
      }, {
        src: require('../assets/banner2.jpg'),
        text: '芝士未来 — 教育招生，先人一步'
      }],
      fuwu: [{
        src: require('../assets/bg_home1.png'),
        icon: require('../assets/bg_home11.png'),
        tit: '舞蹈',
        tip: 'Dance'
      }, {
        src: require('../assets/bg_home2.png'),
        icon: require('../assets/bg_home22.png'),
        tit: '教育',
        tip: 'Education'
      }, {
        src: require('../assets/bg_home3.png'),
        icon: require('../assets/bg_home33.png'),
        tit: '健身',
        tip: 'Fitness'
      }, {
        src: require('../assets/bg_home4.png'),
        icon: require('../assets/bg_home44.png'),
        tit: 'IT',
        tip: 'IT'
      }]
    };
  },
  mounted: function mounted() {
    new swiper('.banner', {
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true
      },
      loop: true,
      effect: 'fade'
    });
    new swiper('.banner2', {
      speed: 1000,
      spaceBetween: 25,
      freeMode: true,
      slidesPerView: 4 //可见页面4

    });
  },
  created: function created() {},
  methods: {
    topath: function topath() {
      this.$router.push({
        path: '/institution'
      });
    }
  }
};
import heade from "./components/public/header.vue";
import foote from "./components/public/foote.vue";
export default {
  components: {
    heade: heade,
    foote: foote
  },
  data: function data() {
    return {};
  }
};
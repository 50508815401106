import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import { getCurrentInstance, reactive } from "vue";
var postdata = reactive({
  userName: '',
  phoneNumber: '',
  cooperateIntentionText: ''
});
export default {
  name: 'institution',
  setup: function setup() {
    var $http = getCurrentInstance().appContext.config.globalProperties.$http;

    var postInst = function postInst() {
      if (!postdata.userName || postdata.userName.length > 30 || !postdata.phoneNumber || !postdata.cooperateIntentionText || postdata.cooperateIntentionText.length > 180) {
        ElMessage.error('请输入规范信息！');
        return;
      }

      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //手机号 11位且符合标准

      if (!reg_tel.test(postdata.phoneNumber)) {
        ElMessage.error("请填写正确的手机号码！");
        return;
      }

      $http('/buscooperate/saveIntentionText', postdata).then(function (res) {
        if (res.data.code == '000000') {
          postdata.userName = '';
          postdata.phoneNumber = '';
          postdata.cooperateIntentionText = '';
          ElMessage.success('提交成功，期待您的入驻！');
        }
      });
    };

    return {
      postInst: postInst,
      postdata: postdata
    };
  }
};
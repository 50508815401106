export default {
  name: 'about',
  mounted: function mounted() {
    this.mapInfo();
  },
  methods: {
    mapInfo: function mapInfo() {
      this.$nextTick(function () {
        var center = new window.TMap.LatLng(40.000385, 116.387837);
        var map = new window.TMap.Map(document.getElementById('container'), {
          center: center,
          //设置地图中心点坐标
          zoom: 17.1,
          //设置地图缩放级别
          pitch: 30,
          //设置俯仰角
          rotation: -90 //设置地图旋转角度

        });
        var infoWindow = new TMap.InfoWindow({
          map: map,
          position: center,
          //设置infoWindow，content支持直接传入html代码，以实现各类内容格式需求
          content: "<div><img style='width:100px;height:auto' src='" + require('../assets/logo.png') + "'></div>"
        });
      });
    }
  }
};
export default {
  name: 'project',
  data: function data() {
    return {
      contlist: [{
        src: require('../assets/project_bg1.png'),
        tit: '芝易学',
        con: '帮助教育机构扩大招生量及报名转化率。有效缓解学员一次性缴费压力，建立教育消费方与培训机构之间的信任，建立契约后可按月支付，教育机构可根据自身需求，灵活定制方案。'
      }, {
        src: require('../assets/project_bg2.png'),
        tit: '信用卡',
        con: '付款人使用其信用卡在额度充足的前提下进行支付的付款方式，支持160多家银行信用卡多种账期选择，灵活付费。'
      }, // {src: require('../assets/project_bg3.png'),tit:'芝士支付',
      // con:'通道稳定，收款便捷，覆盖面广，账户安全，帮助机构快速关单。'},
      {
        src: require('../assets/project_bg4.png'),
        tit: '电子签约',
        con: '实现线上无接触签约，解决传统签约痛点，简化机构签约过程，提高机构报名转化率，符合《电子签约法》《合同法》等国家法律规定，更好的保障机构权益。'
      }]
    };
  }
};
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import qs from "qs";

import './assets/css/global.css' /*引入公共样式*/
import 'element-plus/dist/index.css'
import { ElMessage } from 'element-plus'

// 创建axios实例
const instance = axios.create({baseURL:'/learnBorrowBoss',timeout: 1000 * 500 });
// 设置post请求头
instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => {
    if(res.status === 200){
      if(res.data.code=="000000"){
        return res
      }else{
        if(res.data.msg!='null'){
          ElMessage.error(`${res.data.msg}`);
        }
      }
    }else{
      ElMessage.error(`${res.data.msg}`);
    }
  },
  // 请求失败
  error => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      ElMessage.error(`${response}`);
      // errorHandle(response.status, response.data.message);
      return response
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      //store.commit("changeNetwork", false);
    }
  }
);

const app = createApp(App);
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
app.config.globalProperties.$poste = function(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, qs.stringify(data)).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
}
app.config.globalProperties.$http =(url, data = {}) =>{
    return new Promise((resolve, reject) => {
        instance.post(url, qs.stringify(data)).then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
      });
}
app.use(store).use(router).mount('#app')

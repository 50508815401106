export default {
  data: function data() {
    return {
      navlist: [{
        name: '首页',
        path: '/'
      }, {
        name: '产品介绍',
        path: '/project'
      }, {
        name: '关于我们',
        path: '/about'
      }, {
        name: '机构入驻',
        path: '/institution'
      } // {name:'机构后台',path:'/s'}
      ],
      activenav: '/'
    };
  },
  watch: {
    $route: {
      handler: function handler(val, oldval) {
        this.activenav = val.path;
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    routto: function routto(item) {
      this.$router.push({
        path: item.path
      });
    }
  }
};